import { useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import { Category, Measure } from '@lib/labels';
import { Box, Card, CardContent, CardHeader, Grid } from '@mui/material';
import { useMemo } from 'react';
import { RIOT_BLUE } from 'theme';
import { RoomReadings } from '../types';
import { getStockImageByRoom } from '../utils';
import CategoryReadingIcon from './CategoryReadingIcon';

type Props = {
  areaName: string;
  deviceName: string;
  roomReadings: Omit<RoomReadings, 'name'>;
};

const RoomStatus = ({ areaName, deviceName, roomReadings }: Props) => {
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);
  const currentTenant = useMemo(
    () => tenant.currentTenant || userTenant,
    [tenant.currentTenant, userTenant]
  );
  const themeColor = useMemo(
    () => currentTenant.builder_color || RIOT_BLUE,
    [currentTenant.builder_color]
  );

  return (
    <Card sx={{ p: 0 }}>
      <CardHeader
        title={`${areaName} - ${deviceName}`}
        sx={{
          color: themeColor,
          opacity: 1,
          height: '4em',
          padding: '0.5em',
          textTransform: 'capitalize',
          '& .MuiCardHeader-content': {
            display: 'flex',
            height: '5em',
            flexDirection: 'column',
            justifyContent: 'center',
          },
          '& .MuiTypography-h5': {
            fontSize: '1.2em',
            fontWeight: '700',
          },
        }}
      />
      <CardContent
        sx={{
          height: '15rem',
          width: 'auto',
          padding: 0,
        }}
      >
        <Box
          component="div"
          sx={{
            height: 'inherit',
            width: 'inherit',
            backgroundImage: `url("${getStockImageByRoom(areaName)}")`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundColor: 'rgba(255, 255, 255, 0.3)',
            backgroundSize: 'cover',
            position: 'relative',
            '&::before': {
              content: '""',
              position: 'absolute',
              top: '0px',
              right: '0px',
              bottom: '0px',
              left: '0px',
              background: 'rgba(255, 255, 255, 0.7)',
            },
          }}
        ></Box>
        <Grid
          container
          columns={{ lg: 2 }}
          sx={{
            height: 'inherit',
            width: 'inherit',
            position: 'relative',
            bottom: '16em',
            padding: '3px',
            overflow: 'scroll',
            paddingTop: '10px',
          }}
          alignContent="start"
          justifyContent="stretch"
        >
          {Object.entries(roomReadings.readings).map(
            ([category, reading], idx) =>
              Object.entries(reading).map(([measureType, measures]) => {
                return (
                  <Grid item xs={1} p={1}>
                    <CategoryReadingIcon
                      key={idx}
                      category={category as Category}
                      measure={measureType as Measure}
                      readings={measures}
                      size="2.33rem"
                    />
                  </Grid>
                );
              })
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default RoomStatus;
