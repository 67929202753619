import { CognitoUser } from "@aws-amplify/auth";
import AppFrame from "@features/Common/AppFrame";
import HomeViews from "@features/Common/HomeViews";
import { setUser } from "@features/aws-user/slice";
import { UserData } from "@features/aws-user/types";
import {
  Box,
  CssBaseline,
  ThemeProvider
} from '@mui/material';
import AllBuilders from "@views/all-builders";
import AllContacts from "@views/all-contacts";
import AllDocuments from "@views/all-documents";
import AllHomeowners from "@views/all-homeowners";
import AllHomes from "@views/all-homes";
import AllNeighborhoods from "@views/all-neighborhoods";
import AllPlans from "@views/all-plans";
import AllUsers from "@views/all-users";
import AllUtilityGroups from "@views/all-utilgroups";
import Analytics from "@views/analytics";
import CommissionHome from "@views/commission-home";
import CreateHome from "@views/create-home";
import CurrentStatus from "@views/current-status";
import HomeAnalyticsView from "@views/home-analytics";
import HomeCases from "@views/home-cases";
import HomeDetails from "@views/home-details";
import HomeEvseView from "@views/home-evse";
import HomeHealth from "@views/home-health";
import HomeNotifications from "@views/home-notifications";
import Usage from "@views/home-utility-monitoring";
import PendingHomes from "@views/homes-pending-sale";
import LiveHomeView from "@views/live-home-view";
import MissingTenant from "@views/missing-tenant";
import NeighborhoodViews from "@views/neighborhood-view";
import Performance from "@views/performance";
import ProvisionHome from "@views/provision-home";
import Settings from "@views/settings";
import SupportCaseView from "@views/support-case";
import UtilityGroupView from "@views/utility-group";
import { Auth } from 'aws-amplify';
import { useEffect, useMemo, useRef, useState } from "react";
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import AllDevices from "views/all-devices";
import initRum from '../lib/cloudwatch-rum';
import { useAppDispatch, useAppSelector } from "./hooks";
import { RootState } from "./store";
import theme from './theme';
import NeighborhoodSummaryView from "@features/neighborhood/components/NeighborhoodSummaryView";
import NeighborhoodUsageView from "@features/neighborhood/components/NeighborhoodUsageView";
import NeighborhoodNotificationsView from "@features/neighborhood/components/NeighborhoodNotificationsView";
import NeighborhoodPerformanceView from "@features/neighborhood/components/NeighborhoodPerformanceView";
import AllSceneTemplates from "@views/all-scenetemplates";

const App = () => {
  const appContainerRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();

  const themeCfg = theme;
  const awsUser = useAppSelector((state: RootState) => state.awsUser);
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant?.currentTenant || userTenant, [tenant?.currentTenant, userTenant]);

  const isMissingTenant = useMemo(() => (
    awsUser.idToken
      && !userTenant.checkingTenant
      && !userTenant.checkingTenants
      && !userTenant?.tenant_id
      && !currentTenant?.tenant_id
  ), [awsUser.idToken, currentTenant?.tenant_id, userTenant.checkingTenant, userTenant.checkingTenants, userTenant?.tenant_id]);

  /* Pass session to cloudwatch RUM when session cahnges */
  useEffect(() => {
    if (awsUser?.idToken) {
      const rum = initRum();
      Auth.currentUserCredentials().then(c => {
        if (rum && c) rum.setAwsCredentials(c);
      }).catch(e => {
        console.error('Failed to obtain credentials for RUM: %o', e)
      })
    }
  }, [awsUser?.idToken]);

  const [refreshed, setRefreshed] = useState<boolean>(false);

  useEffect(() => {
    const id = setInterval(async () => {
      try {
        // gets current session and refreshes if needed
        const session = await Auth.currentSession();
        const refreshToken = session.getRefreshToken();
        const user: CognitoUser = await Auth.currentAuthenticatedUser();

        await new Promise<void>((res, rej) => {
          user.refreshSession(refreshToken, (err) => {
            if (err) rej(err);
            else res();
          });
        });

        const token = user.getSignInUserSession()?.getIdToken().getJwtToken();
        dispatch(setUser({
          ...awsUser,
          idToken: token || '',
        }));

        setRefreshed(!refreshed);
      } catch (e) {
        console.error('Unable to refresh Token', e);
        dispatch(setUser({
          idToken: '',
          username: '',
          attributes: null,
        } as UserData));
        Auth.signOut();
      }

    }, 60*60*1000);

    return () => clearInterval(id);

  }, [awsUser, awsUser.idToken, dispatch, refreshed]);

  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={themeCfg}>
        <Box component="div" ref={appContainerRef}>
          <Router>
            <Routes>
              {isMissingTenant ? (
                <Route path='' element={<AppFrame />}>
                  <Route index element={<MissingTenant />} />
                  <Route path="*" element={<MissingTenant />} />
                </Route>
              ) : (
                <Route path='' element={<AppFrame />}>
                  <Route index element={<AllHomes />} />
                  <Route path='homes' element={<AllHomes />} />
                  <Route path='homes/pending' element={<PendingHomes />} />
                  <Route path='homes/new' element={<HomeViews kiosk />}>
                    <Route index element={<CreateHome />} />
                    <Route path=":property_id/provision" element={<ProvisionHome />} />
                    <Route path=":property_id/commission" element={<CommissionHome />} />
                  </Route>
                  <Route path='homes/:property_id' element={<HomeViews />}>
                    <Route index element={<CurrentStatus />} />
                    <Route path='usage' element={<Usage />} />
                    <Route path='performance' element={<HomeAnalyticsView />} />
                    <Route path='charts' element={<Performance />} />
                    <Route path='current' element={<CurrentStatus />} />
                    <Route path='details' element={<HomeDetails />} />
                    <Route path='notifications' element={<HomeNotifications />} />
                    <Route path='health' element={<HomeHealth />} />
                    <Route path='evse' element={<HomeEvseView />} />
                    <Route path='support' element={<HomeCases />} />
                    <Route path='support/:case_id' element={<SupportCaseView />} />
                  </Route>
                  <Route path="utility-groups" element={<AllUtilityGroups />} />
                  <Route path="utility-groups/:group_id" element={<UtilityGroupView />} />
                  <Route path='analytics' element={<Analytics />} />
                  <Route path="neighborhoods" element={<AllNeighborhoods />} />
                  <Route path="neighborhoods/:neighborhood_id" element={<NeighborhoodViews />}>
                    <Route index element={<NeighborhoodSummaryView />} />
                    <Route path="usage" element={<NeighborhoodUsageView />} />
                    <Route path="performance" element={<NeighborhoodPerformanceView />} />
                    <Route path="notifications" element={<NeighborhoodNotificationsView />} />
                  </Route>
                  <Route path='users' element={<AllUsers />} />
                  <Route path='contacts' element={<AllContacts />} />
                  <Route path='documents' element={<AllDocuments />} />
                  <Route path='plans' element={<AllPlans />} />
                  <Route path="devices" element={<AllDevices />} />
                  <Route path="scenetemplates" element={<AllSceneTemplates />} />
                  <Route path='builders' element={<AllBuilders />} />
                  <Route path='homeowners' element={<AllHomeowners />} />
                  <Route path='settings' element={<Settings />} />
                  <Route path="*" element={<Navigate replace to="/homes" />} />
                </Route>
              )}
              <Route path='kiosk' element={<AppFrame kiosk />}>
                <Route path='homes/:property_id' element={<HomeViews kiosk />}>
                  <Route index element={<LiveHomeView />} />
                </Route>
              </Route>
            </Routes>
          </Router>
        </Box>
      </ThemeProvider>
    </>
  );
}

export default App;
