import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import ExpandableSnack from "@features/Common/ExpandableSnack";
import { PropertyManifestEntry } from "@features/home-manifest/types";
import { useUpdatePropertyMutation } from "@features/home/api";
import { setProperty } from "@features/home/slice";
import { ManifestDevice } from "@features/plan-manifest/types";
import { Category } from "@lib/labels";
import { ApiErrorResponse, SnackType } from "@lib/types";
import { getErrorMessage } from '@lib/utils';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import { Button, Card, CardActions, CardContent, CardHeader, Divider, IconButton, Stack, Tab, Tooltip } from "@mui/material";
import { Formik, FormikValues } from "formik";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { ProvisioningForm, ProvisioningStep } from "../types";
import ProvisionDevicesPanel from "./provision-devices/ProvisionDevicesPanel";
import ProvisionGatewayPanel from "./provision-gateway/ProvisionGatewayPanel";
import ProvisionNetworkPanel from "./provision-network/ProvisionNetworkPanel";
import ReviewPanel from "./provision-review/ReviewPanel";


const initialFormValues = {
  switchStatus: false,
  routerStatus: false,
  accessPointStatus: false,
  gatewayCableStatus: false,
  gatewayProvisioningStatus: false,
  kioskCableStatus: false,
  kioskProvisioningStatus: false,
} as ProvisioningForm;


const Provisioning = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const selectedTenant = useAppSelector((state: RootState) => state.tenant);
  const property = useAppSelector((state: RootState) => state.property);

  const manifestEntries = useMemo(() => property.manifestEntries, [property.manifestEntries]);
  const currentTenant = useMemo(() => selectedTenant.currentTenant || userTenant, [selectedTenant.currentTenant, userTenant]);

  const [activeStep, setActiveStep] = useState<ProvisioningStep>(ProvisioningStep.network);
  const [networkProvisioned, setNetworkProvisioned] = useState<Boolean>(false);
  const [gatewayProvisioned, setGatewayProvisioned] = useState<Boolean>(false);

  const anyProvisioned = useMemo(() => manifestEntries
    ?.some((e: PropertyManifestEntry) => e.provisioned
    && !(e.device as ManifestDevice)?.sensors?.some(s => s.sensor_category === Category.system)), [manifestEntries]);
  const allProvisioned = useMemo(() => manifestEntries
    ?.every((e: PropertyManifestEntry) => e.provisioned || (e.device as ManifestDevice)?.sensors
      ?.some(s => s.sensor_category === Category.system)), [manifestEntries]);

  const allMatched = useMemo(() => manifestEntries
    ?.every(e => (e.device as ManifestDevice)?.sensors
      ?.every(s => !!e.sensor_map?.[s.sensor_id ?? s.friendly_name])
      || (e.device as ManifestDevice)?.sensors
        ?.some(s => s.sensor_category === Category.system)), [manifestEntries]);

  /* Skip hardware setup if any devices are already provisioned */
  const [skippedHardwareSetup, setSkippedHardwareSetup] = useState<boolean>(false);

  useEffect(() => {
    if (!skippedHardwareSetup) {
      const onHardwareStep = activeStep === ProvisioningStep.gateway || activeStep === ProvisioningStep.network;

      if (onHardwareStep && anyProvisioned) {
        setSkippedHardwareSetup(true);
        setNetworkProvisioned(true);
        setGatewayProvisioned(true);
        setActiveStep(ProvisioningStep.devices);
      }
    }
  }, [activeStep, anyProvisioned, skippedHardwareSetup]);

  /**
   * Runs a check that all manifest entries are marked as provisioned,
   * and if so, marks property as provisioned
   */
  const isAllProvisioned = useMemo(() => allProvisioned && allMatched, [allMatched, allProvisioned]);

  const [
    updateProperty,
  ] = useUpdatePropertyMutation();


  const onSubmit = useCallback(async (
    values: FormikValues,
  ) => {
    if (isAllProvisioned) {
      const updatedProperty = await updateProperty({
        userTenantId: currentTenant?.tenant_id || '',
        propertyId: property?.property_id || '',
        body: {
          provisioned: Math.round(Date.now() / 1000).toString()
        }
      });

      const errorDetails = (updatedProperty as ApiErrorResponse)?.error;

      if (errorDetails) {
        enqueueSnackbar("Couldn't complete home provisioning:", {
          key: "provisioning-error",
          content: (
            <ExpandableSnack
              id="provisioning-error"
              message={"Couldn't complete home provisioning:"}
              variant={SnackType.error}
              detail={getErrorMessage(errorDetails)}
            />),
        });
      } else {
        enqueueSnackbar("Completed home provisioning", {
          variant: "success",
        });
        navigate(`/homes/${property.property_id}`);
      }
    }
  }, [currentTenant?.tenant_id, enqueueSnackbar, isAllProvisioned, navigate, property.property_id, updateProperty]);

  /** Navigate to all-homes view on Cancel */
  const onCancel = useCallback(() => {
    dispatch(setProperty(null));
    navigate('/homes');

  }, [dispatch, navigate]);

  const submitNetwork = useCallback(() => {
    setNetworkProvisioned(true);
    setActiveStep(ProvisioningStep.gateway);
  }, []);

  const submitGateway = useCallback(() => {
    setGatewayProvisioned(true);
    setActiveStep(ProvisioningStep.devices);
  }, []);

  const submitDevices = useCallback(() => {
    setActiveStep(ProvisioningStep.review);
  }, []);

  const isNetworkSetup = (values: Partial<ProvisioningForm>) => {
    const { switchStatus, routerStatus, accessPointStatus } = values;
    return switchStatus && routerStatus && accessPointStatus;
  }

  const isGatewaySetup = (values: Partial<ProvisioningForm>) => {
    const { gatewayCableStatus, gatewayProvisioningStatus } = values;
    return gatewayCableStatus && gatewayProvisioningStatus;
  }

  const disableTab = useCallback((step: ProvisioningStep) => {
    switch (step) {
      default:
      case ProvisioningStep.network:
        return false;
      case ProvisioningStep.gateway:
        return !networkProvisioned;
      case ProvisioningStep.devices:
        return !networkProvisioned || !gatewayProvisioned;
    }

  }, [gatewayProvisioned, networkProvisioned]);

  const disableNextStepButton = useCallback((values:  Partial<ProvisioningForm>) => {
    switch (activeStep) {
      default:
      case ProvisioningStep.devices:
        return false;
      case ProvisioningStep.network:
        return !isNetworkSetup(values);
      case ProvisioningStep.gateway:
        return !isGatewaySetup(values);
      case ProvisioningStep.review:
        return !allProvisioned;
    }
  }, [activeStep, allProvisioned]);

  const handleClickNext = useCallback(() => {
    switch (activeStep) {
      default:
        break;
      case ProvisioningStep.network:
        submitNetwork();
        break;
      case ProvisioningStep.gateway:
        submitGateway();
        break;
      case ProvisioningStep.devices:
        submitDevices();
        break;
    }
  }, [activeStep, submitDevices, submitGateway, submitNetwork]);

  return (
      <Formik
        validateOnChange
        initialValues={initialFormValues}
        onSubmit={onSubmit}
      >
        {f => (
          <form noValidate onSubmit={f.handleSubmit}>
            <TabContext value={activeStep}>
              <Card sx={{
                width: '90%',
              }}>
                <CardHeader
                  sx={{
                    padding: 0,
                  }}
                  title={
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                      <TabList onChange={(e, step) => setActiveStep(step)} sx={{ padding: 0 }}>
                      {
                        Object.values(ProvisioningStep)
                          .map(step => (
                            <Tab
                              key={`provisioning-step-header-${step}`}
                              label={step}
                              value={step}
                              disabled={disableTab(step)}
                            />
                          ))
                      }
                    </TabList>
                      <Tooltip title="Exit provisioning flow" placement="top" arrow>
                        <span>
                          <IconButton onClick={onCancel}><CloseIcon /></IconButton>
                        </span>
                      </Tooltip>
                    </Stack>

                  }
                />
                <Divider />
                <CardContent sx={{ height: '43em', padding: 1 }}>
                  <TabPanel value={ProvisioningStep.network}>
                    <ProvisionNetworkPanel />
                  </TabPanel>
                  <TabPanel value={ProvisioningStep.gateway}>
                    <ProvisionGatewayPanel />
                  </TabPanel>
                  <TabPanel value={ProvisioningStep.devices}>
                    <ProvisionDevicesPanel />
                  </TabPanel>
                  <TabPanel value={ProvisioningStep.review}>
                    <ReviewPanel />
                  </TabPanel>
                </CardContent>
                <Divider />
                <CardActions sx={{ justifyContent: 'end' }}>
                  {
                    activeStep !== ProvisioningStep.review &&
                    <Button
                      variant="contained"
                      disabled={disableNextStepButton(f.values)}
                      onClick={handleClickNext}
                    >Next</Button>
                  }
                  {
                    activeStep === ProvisioningStep.review &&
                    <Stack direction="row" spacing={1}>
                      <Button
                        variant="outlined"
                        onClick={onCancel}
                      >
                        Finish Later
                      </Button>
                      <LoadingButton
                        loading={f.isSubmitting}
                        type="submit"
                        variant="contained"
                        disabled={disableNextStepButton(f.values)}
                      >Finish Provisioning</LoadingButton>
                    </Stack>
                  }
                </CardActions>
              </Card>
            </TabContext>
          </form>
        )}
      </Formik>
  );
}

export default Provisioning;