/**
 * Main application frame, including navigation bar
 */
import { useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import PreferencesProvider from '@features/settings/components/PreferencesProvider';
import TenantSelector from '@features/tenant-selector/TenantSelector';
import UserTenantProvider from '@features/userTenant/UserTenantProvider';
import { TenantType } from '@features/userTenant/types';
import { checkPermission } from '@features/userTenant/utils';
import NeighborhoodsIcon from '@mui/icons-material/Apartment';
import AnalyticsIcon from '@mui/icons-material/Assessment';
import PlansIcon from '@mui/icons-material/BackupTable';
import CloseIcon from '@mui/icons-material/ChevronLeft';
import OpenIcon from '@mui/icons-material/ChevronRight';
import BuildersIcon from '@mui/icons-material/Construction';
import DataThresholdingIcon from '@mui/icons-material/DataThresholding';
import DevicesIcon from '@mui/icons-material/DevicesOther';
import SceneTemplatesIcon from '@mui/icons-material/Polyline';
import HomeownersIcon from '@mui/icons-material/FamilyRestroom';
import HomesIcon from '@mui/icons-material/HolidayVillage';
import DocumentsIcon from '@mui/icons-material/LibraryBooks';
import SalesIcon from '@mui/icons-material/PendingActions';
import UsersIcon from '@mui/icons-material/People';
import ContactsIcon from '@mui/icons-material/RecentActors';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip
} from '@mui/material';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { RIOT_BLUE } from 'theme';

type Props = {
  kiosk?: boolean;
}

const AppFrame = ({ kiosk }: Props) => {
  const navigate = useNavigate();
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const themeColor = useMemo(() => currentTenant?.builder_color || RIOT_BLUE, [currentTenant?.builder_color]);
  const userPermissions = useMemo(() => userTenant.userPermissions, [userTenant.userPermissions]);

  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  /**
   * Add an item to the drawer menu
   *
   * @param text Text to display next to icon
   * @param path Path to new page
   * @param icon Icon component to display
   * @param permission Permission check key (optional)
   */
  const renderMenuItem = useCallback((
    text: string,
    path: string,
    icon: ReactNode,
    permission: string | null = null
  ) => {

    if (permission && !checkPermission(userPermissions, permission)) {
      // User is not permitted to see this
      return null;
    }

    const item = (
      <ListItemButton onClick={() => path && navigate(path)} sx={{ color: themeColor }}>
        <ListItemIcon sx={{ color: themeColor }}>
          {icon}
        </ListItemIcon>
        {drawerOpen && <ListItemText primary={text} />}
      </ListItemButton>
    )

    if (drawerOpen) {
      return item;
    }

    return (
      <Tooltip title={text} placement="right" arrow>
        { item }
      </Tooltip>
    );
  }, [drawerOpen, navigate, themeColor, userPermissions]);

  /** Render drawer and Main Content */
  const renderMainContent = useCallback(() => (
    <Box
      component="div"
      sx={{
        // Fill the available space
        flex: "1 1 auto",
        width: "100%",
        height: "100vh",
        overflow: "auto",
        padding: 0,
        margin: 0,
        // Setup a Flex row
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <Drawer
        hidden={kiosk}
        variant="permanent"
        open={drawerOpen}
        sx={{
          whiteSpace: 'nowrap',
          boxSizing: 'border-box',
          width: drawerOpen ? "15em" : "4em",
          height: '100vh',
          zIndex: 15,
          overflow: 'hidden',
          '& .MuiDrawer-paper': {
            // Don't scroll
            overflowX: 'hidden',
            // Stay in this row
            position: 'static',
          },
          '& .MuiListItemIcon-root': {
            // Keep the top/bottom margins that the text would have
            // included so the icons don't move.
            marginTop: 0.5,
            marginBottom: 0.5,
            ...(
              !drawerOpen && {
                // Remove the icon/text gap
                minWidth: 'unset',
              }
            )
          },
        }}
      >
        <Box
          component="div"
          sx={{
            // Fill the available space
            flex: '1 1 100%',
            // Setup a Flex column
            display: 'flex',
            flexDirection: 'column',
            // Allow the menu items to scroll
            overflowY: 'auto',
          }}
        >
          <List>
            {
              currentTenant && currentTenant.tenant_type === TenantType.System &&
              renderMenuItem('Sales', '/homes/pending', <SalesIcon />, 'delete-tenants')
            }
            {
              currentTenant && currentTenant.tenant_type === TenantType.System &&
              renderMenuItem('System Analytics', '/analytics', <AnalyticsIcon />, 'read-properties')
            }
            {renderMenuItem('Homes', '/homes', <HomesIcon />, 'read-properties')}
            {
              currentTenant && [TenantType.System, TenantType.Builder, TenantType.PropertyManager].includes(currentTenant.tenant_type) &&
              renderMenuItem('Neighborhoods', '/neighborhoods', <NeighborhoodsIcon />, 'read-properties')
            }
            {
              currentTenant && [TenantType.System].includes(currentTenant.tenant_type)
                && renderMenuItem('Utility Groups', '/utility-groups', <DataThresholdingIcon />, 'manage-plans')
            }
            {renderMenuItem('Documents', '/documents', <DocumentsIcon />, 'read-documents')}
            {renderMenuItem('Contacts','/contacts', <ContactsIcon />, 'read-contacts')}
            {renderMenuItem('Plans','/plans', <PlansIcon />, 'manage-plans')}
            {renderMenuItem('Device Library', '/devices', <DevicesIcon />, 'manage-devices')}
            {renderMenuItem('Scene Templates', '/scenetemplates', <SceneTemplatesIcon />, 'manage-scenetemplates')}
            {
              currentTenant && currentTenant.tenant_type === TenantType.System &&
              renderMenuItem('Builders', '/builders', <BuildersIcon />, 'delete-tenants')
            }
            {
              currentTenant?.tenant_type !== undefined
                && [TenantType.System, TenantType.Builder].includes(currentTenant.tenant_type!)
                && renderMenuItem('Homeowners', '/homeowners', <HomeownersIcon />, 'delete-tenants')
            }
            {renderMenuItem('Users', '/users', <UsersIcon />, 'manage-users')}
            {renderMenuItem('Settings', '/settings', <SettingsIcon />)}
          </List>
        </Box>
        <Box component="div">
          <ListItem disableGutters>
            <TenantSelector expanded={drawerOpen} />
          </ListItem>
          <ListItem disableGutters>
            <ListItemButton onClick={() => setDrawerOpen(!drawerOpen)}>
              <ListItemIcon sx={{ minWidth: 0}}>
                { drawerOpen ? <CloseIcon /> : <OpenIcon /> }
              </ListItemIcon>
              { drawerOpen && <ListItemText primary="Close" /> }
            </ListItemButton>
          </ListItem>
        </Box>
      </Drawer>

      { /* Main Container */}
      <Box
        component="div"
        sx={{
          // Fill the remaining space
          flex: "1 1 auto",
          width: "100%",
          height: "100vh",
          // Allow the content to scroll within this content well
          overflow: "auto",
          // Setup a Flex column for the children
          display: 'flex',
          flexDirection: 'column',
          padding: 2,
        }}
      >
        <Outlet />
      </Box>
    </Box>
  ), [currentTenant, drawerOpen, kiosk, renderMenuItem]);

  /** MAIN RENDER */
  return (
    <UserTenantProvider>
      <PreferencesProvider>
        <Box
          component="div"
          sx={{
            // Fill the viewport
            flex: '1 1 auto',
            width: '100%',
            height: '100vh',
            // Setup a Flex column
            display: 'flex',
            flexDirection: 'column',
            // Force the scrolling into the children
            overflow: 'hidden',
          }}
        >
          {renderMainContent()}
        </Box>
      </PreferencesProvider>
    </UserTenantProvider>
  );

}

export default AppFrame;
