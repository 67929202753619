import { useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import { PropertyManifestEntry } from '@features/home-manifest/types';
import { ManifestDevice } from '@features/plan-manifest/types';
import { Category, Measure } from '@lib/labels';
import { Alert, Card, CardContent, CardHeader, Divider, Grid, Stack } from "@mui/material";
import { useMemo } from 'react';
import AvailablePowerOutput from './AvailablePowerOutput';
import CableStatus from './CableStatus';
import ChargeStatus from './ChargeStatus';
import CurrentPowerOutput from './CurrentPowerOutput';
import EvseChart from './EvseChart';
import EvseStatus from './EvseStatus';
import MaxPowerOutput from './MaxPowerOutput';
import TimeInSession from './TimeInSession';
import TotalEnergyOutput from './TotalEnergyOutput';


const NO_EV_MESSAGE = 'No EV equipment detected in the home manifest.';

const HomeEvse = () => {
  const property = useAppSelector((state: RootState) => state.property);

  const manifestEntries = useMemo(() => property.manifestEntries || [], [property.manifestEntries]);

  const evseEntry = useMemo(() => manifestEntries
    .find((e: PropertyManifestEntry) => (
      (e.device as ManifestDevice)?.sensors?.some(ss => (
        [Category.cable].includes(ss.sensor_category)
        && [Measure.status].includes(ss.measure_name)))
    )), [manifestEntries]);

  return (
    <Grid container columns={{ xs: 1, md: 2 }} spacing={1} alignItems="center">
      <Grid
        item
        xs={1}
        container
        columns={{ xs: 1, md: 2 }}
        sx={{ p: "1em" }}
        spacing={2}
      >
        {!evseEntry && (
          <Grid item xs={2}>
            <Alert
              severity="warning"
              variant="outlined"
              sx={{
                width: "auto",
                background: "white",
              }}
            >
              {NO_EV_MESSAGE}
            </Alert>
          </Grid>
        )}
        <Grid item xs={2}>
          <Card sx={{ minHeight: "16em" }}>
            <CardHeader title="Charging Status" />
            <Divider />
            <CardContent>
              <Stack spacing={1}>
                <CableStatus evseEntry={evseEntry} />
                <EvseStatus evseEntry={evseEntry} />
                <ChargeStatus evseEntry={evseEntry} />
                <MaxPowerOutput evseEntry={evseEntry} />
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={2}>
          <Card sx={{ minHeight: "16em" }}>
            <CardHeader title="Session Details" />
            <Divider />
            <CardContent>
              <Stack spacing={1}>
                <TimeInSession evseEntry={evseEntry} />
                <AvailablePowerOutput evseEntry={evseEntry} />
                <CurrentPowerOutput evseEntry={evseEntry} />
                <TotalEnergyOutput evseEntry={evseEntry} />
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid item xs={1}>
        <EvseChart evseEntry={evseEntry} />
      </Grid>
    </Grid>
  );
}

export default HomeEvse;