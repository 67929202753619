import { useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import {
  Category,
  findMeasureLabel,
  getMeasuresByCategory,
  Measure,
} from '@lib/labels';
import { formatLabel } from '@lib/utils';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import { Button, Card, CardContent, CardHeader, Divider, Grid, IconButton, Menu, MenuItem, Stack, Tooltip } from '@mui/material';
import { MouseEvent, useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { CompareLabel, Interval } from '../types';
import CurrentReadings from './CurrentReadings';
import PerformanceChart from './PerformanceChart';

const HomeAnalytics = () => {
  const home = useAppSelector((state: RootState) => state.property);
  const propertyId = useMemo(
    () => home?.property_id || '',
    [home?.property_id]
  );

  const [category, setCategory] = useState<Category>(Category.air);
  const [measures] = useState<Partial<Record<Category, Measure[]>>>({
    [Category.air]: getMeasuresByCategory(Category.air),
    [Category.light]: getMeasuresByCategory(Category.light),
  });
  const [measure, setMeasure] = useState<Measure>(Measure.temperature);
  const [interval, setInterval] = useState<Interval>(Interval.MONTH);
  const [compare, setCompare] = useState<CompareLabel>(CompareLabel.devices);

  const [measureAnchorEl, setMeasureAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const [compareAnchorEl, setCompareAnchorEl] = useState<null | HTMLElement>(
    null
  );

  const handleOpenMeasureMenu = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      setMeasureAnchorEl(event.currentTarget);
    },
    []
  );

  const handleCloseMeasureMenu = useCallback(() => {
    setMeasureAnchorEl(null);
  }, []);

  const handleSelectMeasure = useCallback(
    (meas) => {
      setMeasure(meas);
      setMeasureAnchorEl(null);
      if (measures[Category.air] && measures[Category.air].indexOf(meas) > -1) {
        setCategory(Category.air);
      } else {
        setCategory(Category.light);
      }
    },
    [measures]
  );

  const selectingMeasure = useMemo(
    () => Boolean(measureAnchorEl),
    [measureAnchorEl]
  );

  const handleOpenCompareMenu = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      setCompareAnchorEl(event.currentTarget);
    },
    []
  );

  const handleCloseCompareMenu = useCallback(() => {
    setCompareAnchorEl(null);
  }, []);

  const handleSelectCompare = useCallback((label: CompareLabel) => {
    setCompare(label);
    setCompareAnchorEl(null);
  }, []);

  const selectingCompare = useMemo(
    () => Boolean(compareAnchorEl),
    [compareAnchorEl]
  );

  return (
    <Stack spacing={1}>
      <Grid container columns={{ sm: 2, lg: 6 }} spacing={1}>
        <Grid item xs={2} lg={3}>
          <Stack spacing={1}>
            <Card>
              <CardHeader
                title="Controls"
                action={
                  <Tooltip
                    title="Opens Advanced charts in the new window"
                    placement="top"
                    arrow
                  >
                    <span>
                      <IconButton
                        component={Link}
                        to={`/homes/${propertyId}/charts`}
                        target="blank"
                      >
                        <TroubleshootIcon color="info" />
                      </IconButton>
                    </span>
                  </Tooltip>
                }
              />
              <Divider />
              <CardContent>
                <Stack spacing={1}>
                  <Stack direction="row" spacing={1}>
                    <Button
                      variant="contained"
                      color="info"
                      size="small"
                      onClick={handleOpenMeasureMenu}
                      endIcon={<KeyboardArrowDownIcon />}
                      sx={{
                        width: '100%',
                      }}
                    >
                      {findMeasureLabel(measure)}
                    </Button>
                    <Menu
                      open={selectingMeasure}
                      onClose={handleCloseMeasureMenu}
                      anchorEl={measureAnchorEl}
                    >
                      {Object.values(measures)
                        .flat()
                        .map((meas) => (
                          <MenuItem
                            key={`measure-selector-${meas}`}
                            onClick={() => handleSelectMeasure(meas)}
                            sx={{
                              width: '30em',
                            }}
                          >
                            {findMeasureLabel(meas)}
                          </MenuItem>
                        ))}
                    </Menu>
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    {[
                      Interval.DAY,
                      Interval.WEEK,
                      Interval.MONTH,
                      Interval.YEAR,
                    ].map((int) => (
                      <Button
                        key={`interval-selector-${int}`}
                        onClick={() => setInterval(int)}
                        variant={interval === int ? 'contained' : 'outlined'}
                        color="info"
                        size="small"
                        sx={{
                          width: '25%',
                        }}
                      >
                        {int}
                      </Button>
                    ))}
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <Button
                      variant="contained"
                      color="info"
                      size="small"
                      onClick={handleOpenCompareMenu}
                      endIcon={<KeyboardArrowDownIcon />}
                      sx={{
                        width: '100%',
                      }}
                    >
                      {formatLabel(compare)}
                    </Button>
                    <Menu
                      open={selectingCompare}
                      onClose={handleCloseCompareMenu}
                      anchorEl={compareAnchorEl}
                    >
                      {Object.values(CompareLabel).map((label) => (
                        <MenuItem
                          component={Button}
                          key={`compare-selector-${label}`}
                          onClick={() => handleSelectCompare(label)}
                          sx={{
                            width: '30em',
                          }}
                        >
                          {formatLabel(label)}
                        </MenuItem>
                      ))}
                    </Menu>
                  </Stack>
                </Stack>
              </CardContent>
            </Card>
            <CurrentReadings category={category} measure={measure} />
          </Stack>
        </Grid>
        <Grid item xs={2} lg={3}>
          <PerformanceChart
            category={category}
            measure={measure}
            interval={interval}
            compare={compare}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default HomeAnalytics;