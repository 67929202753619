import { useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import HomeEvse from '@features/home-evse/components/HomeEvse';
import { Alert, Backdrop, Box, Paper, Typography } from '@mui/material';

const HomeEvseView = () => {
  const property = useAppSelector((state: RootState) => state.property);

  /** MAIN RENDER */
  return (
    <>
      <Backdrop open sx={{ zIndex: 1, bottom: '4em', left: '4em' }}>
        <Paper elevation={1}>
          <Alert
            severity="info"
            sx={{
              backgroundColor: 'transparent',
              '& .MuiAlert-icon': {
                fontSize: '3rem',
                display: 'flex',
                alignSelf: 'center',
              },
            }}
          >
            <Typography variant="h2">EVSE Coming Soon</Typography>
          </Alert>
        </Paper>
      </Backdrop>
      <Box
        component="div"
        sx={{
          margin: '1em',
          padding: '2em',
          filter: 'blur(4px)',
        }}
      >
        <Typography variant="h4" sx={{ mb: 6 }}>
          EV Service Equipment at {property?.name}
        </Typography>
        <HomeEvse />
      </Box>
    </>
  );
};

export default HomeEvseView;