import { useAppSelector } from '@app/hooks';
import { RootState } from '@app/store';
import SystemDeviceStatus from "@features/home-status/components/SystemDeviceStatus";
import { useGetDevicesQuery, useHealthcheckMutation } from "@features/home/api";
import { GATEWAY_HEALTHCHECK_NAME, KIOSK_HEALTHCHECK_NAME } from "@features/home/types";
import { formatDate } from '@lib/utils';
import { Alert, Stack, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";

type Props = {
  alert?: boolean;
}

const SystemStatus = ({ alert = false }: Props) => {
  const property = useAppSelector((state: RootState) => state.property);
  const userTenant = useAppSelector((state: RootState) => state.userTenant);
  const tenant = useAppSelector((state: RootState) => state.tenant);

  const currentTenant = useMemo(() => tenant.currentTenant || userTenant, [tenant.currentTenant, userTenant]);
  const devices = useMemo(() => property.devices || [], [property.devices]);
  /** system devices */
  const gateway = useMemo(() => devices.find(dd => dd.data.entity_id.includes(GATEWAY_HEALTHCHECK_NAME)), [devices]);
  const kiosk = useMemo(() => devices.find(dd => dd.data.entity_id.includes(KIOSK_HEALTHCHECK_NAME)), [devices]);

  const [gatewayOffline, setGatewayOffline] = useState<Boolean>(false);
  const [checkDone, setCheckDone] = useState<Boolean>(false);

  const [
    healthcheck,
  ] = useHealthcheckMutation();

  const checkIfPingNeeded = useCallback((): Boolean => {
    // we haven't gotten the devices yet
    if (!devices || !devices.length) return false;
    // don't have system devices at all
    if (!gateway || !kiosk) return true;
    // system devices don't have valid operation events
    if (!gateway.datetime || !kiosk.datetime) return true;
    const oldestAcceptableValue = formatDate(null).add(-15, 'minute');
    const isOldGateway = formatDate(gateway.datetime).isBefore(oldestAcceptableValue);
    // system devices have outdated values
    if (isOldGateway) {
      // if we already checked, we don't want to do it again
      if (checkDone) {
        setGatewayOffline(isOldGateway);
        return false;
      }
       return true;
    }

    setGatewayOffline(false);
    return false;
  }, [devices, gateway, kiosk, checkDone]);

  const [isCheckNeeded, setIsCheckNeeded] = useState<Boolean>(false);

  const {
    refetch: refetchDevices,
  } = useGetDevicesQuery({
    tenantId: currentTenant?.tenant_id || '',
    propertyId: property?.property_id || '',
  }, {
    skip: !currentTenant?.tenant_id || !property.property_id,
  });

  const delay = (ms: number) => new Promise<number>(res => setTimeout(res, ms));

  const doHealthCheck = useCallback(async () => {
      healthcheck({
        userTenantId: userTenant?.tenant_id || '',
        propertyId: property?.property_id || '',
      });
      const id = await delay(15*1000);
      refetchDevices();
      setIsCheckNeeded(false);
      checkIfPingNeeded();
      setCheckDone(true);
      clearTimeout(id);
  }, [checkIfPingNeeded, healthcheck, property?.property_id, refetchDevices, userTenant?.tenant_id])

  useEffect(() => {
    if (isCheckNeeded) {
      doHealthCheck();
    }
  }, [doHealthCheck, isCheckNeeded]);

  // check if we need to send a ping
  useEffect(() => {
    if (userTenant?.tenant_id && property?.property_id && devices) {
      setIsCheckNeeded(checkIfPingNeeded());
    }
  }, [checkIfPingNeeded, devices, property?.property_id, userTenant?.tenant_id]);


  const renderedAlert = useMemo(() => {
    if (gatewayOffline) {
      return (
        <Alert severity='info' variant='standard'>
          Your Gateway appears to be offline.<br />
          Your interactions with notifications will propagate to it when it is back online.
        </Alert>
      );
    }
    return <></>;
  }, [gatewayOffline]);

  const renderedStatus = useMemo(() => (
    <Stack spacing={2} sx={{ width: 'inherit' }}>
      <Typography variant="h5">System status</Typography>
      <Stack direction="row" justifyContent="space-around" sx={{ width: '100%'}}>
        <SystemDeviceStatus
          label="Gateway"
          datetime={gateway?.datetime || 0}
        />
      </Stack>
    </Stack>
  ), [gateway?.datetime]);

  /** MAIN RENDER */
  return alert ? renderedAlert : renderedStatus;
}

export default SystemStatus;